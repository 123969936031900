import thumbnailsLandingProgramCollection from '@griddo-images/thumbnails/LandingProgramCollection.png'
import layoutsVertical from '@griddo-images/layouts/LandingProgramCollection/vertical.png'
import layoutsHorizontal from '@griddo-images/layouts/LandingProgramCollection/horizontal.png'

import Button from '@schemas/components/Button'

const defaultBtn = { ...Button.default }
defaultBtn.appearance = 'link'
defaultBtn.buttonText = 'Ver más'

export default {
  schemaType: 'module',
  displayName: 'Landing Program Collection',
  component: 'LandingProgramCollection',
  category: 'collections',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Icon',
          type: 'ImageField',
          key: 'icon',
          hideable: true,
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          humanReadable: true,
          key: 'detail',
          hideable: true,
          html: true,
        },
        {
          title: 'Elements',
          type: 'ComponentArray',
          mandatory: true,
          key: 'elements',
          whiteList: ['LandingProgramCard'],
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Layout',
          key: 'layout',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'vertical',
              img: layoutsVertical,
            },
            {
              value: 'horizontal',
              img: layoutsHorizontal,
            },
          ],
          columns: 2,
        },
      ],
    },
  ],

  default: {
    component: 'LandingProgramCollection',
    icon: {
      alt: '',
      publicId: 'placeholders/griddo-background',
    },
    title: { content: 'Title', tag: 'h2' },
    subtitle: { content: 'Subtitle', tag: 'h2' },
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    elements: [
      {
        component: 'LandingProgramCard',
        image: {
          alt: '',
          publicId: 'placeholders/griddo-background',
        },
        title: { content: 'Title', tag: 'h2' },
        detail:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        start: '¡Start date!',
        duration: 'Duration',
        mode: 'Online',
        language: 'Castellano',
        btnLabel: '¡Inscríbete ya!',
        hasDistributorData: true,
        data: {
          mode: 'manual',
          sources: [{ structuredData: 'AREA' }],
          order: 'recent',
          quantity: 1,
          fullRelations: true,
        },
      },
      {
        component: 'LandingProgramCard',
        image: {
          alt: '',
          publicId: 'placeholders/griddo-background',
        },
        title: { content: 'Title', tag: 'h2' },
        detail:
          'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        start: 'Start date',
        duration: 'Duration',
        mode: 'Online',
        language: 'Castellano',
        btnLabel: '¡Inscríbete ya!',
        hasDistributorData: true,
        data: {
          mode: 'manual',
          sources: [{ structuredData: 'AREA' }],
          order: 'recent',
          quantity: 1,
          fullRelations: true,
        },
      },
    ],
    line: true,
    layout: 'vertical',
  },

  thumbnails: {
    '1x': thumbnailsLandingProgramCollection,
    '2x': thumbnailsLandingProgramCollection,
  },
}
