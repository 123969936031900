import thumbnailsApplyForm from '@griddo-images/thumbnails/ApplyForm.png'

import BasicMoreInfo from '@schemas/components/BasicMoreInfo'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'template',
  displayName: 'Apply Form',
  component: 'ApplyForm',
  dataPacks: ['PROGRAMS'],
  category: 'forms',
  type: { label: 'Programs', value: 'programs' },
  content: [
    {
      title: 'Header Background Image',
      type: 'ComponentContainer',
      whiteList: ['BackgroundImage'],
      key: 'backgroundImage',
      helptext: 'Recommended size: 1920x260',
    },
    {
      title: 'Main Title',
      type: 'HeadingField',
      key: 'mainTitle',
      default: { tag: 'h1', content: 'Main Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      hideable: true,
      humanReadable: true,
    },
    {
      title: 'Subtitle',
      type: 'HeadingField',
      key: 'subtitle',
      default: { tag: 'h2', content: 'Subtitle' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      hideable: true,
      humanReadable: true,
    },
    {
      title: 'Detail',
      type: 'HeadingField',
      key: 'detail',
      default: {
        tag: 'h3',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi',
      },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      hideable: true,
      humanReadable: true,
    },
    {
      title: 'Button text (step one)',
      type: 'TextField',
      humanReadable: true,
      key: 'stepOneButton',
      mandatory: true,
    },
    {
      title: 'Button submit (step two)',
      type: 'ComponentContainer',
      whiteList: ['Button'],
      key: 'stepTwoButton',
      mandatory: true,
    },
    {
      title: 'Program List',
      type: 'ReferenceField',
      sources: [{ structuredData: 'PROGRAMS' }],
      key: 'data',
      quantity: 0,
      mandatory: true,
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'ApplyForm',
    backgroundImage: {
      component: 'BackgroundImage',
      file: defaultBackgroundImage,
      veil: 50,
    },
    stepOneButton: 'Ir al siguiente paso',
    stepTwoButton: {
      component: 'Button',
      buttonText: 'Solicitar admisión',
      appearance: 'secondary',
      isLink: 'link',
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    hasDistributorData: true,
    data: {
      mode: 'auto',
      sources: [{structuredData: 'PROGRAMS' }],
      order: 'recent',
      quantity: 0,
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...BasicMoreInfo.default }],
    },
  },

  thumbnails: {
    '1x': thumbnailsApplyForm,
    '2x': thumbnailsApplyForm,
  },
}
